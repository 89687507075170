// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
$primary-color: map-get($colors, primary);
$primary-hover-color: map-get($colors, primary-hover);
$warn-color: map-get($colors, red);
$placeholder-color: map-get($colors, grey-7);
$primary-select-color: map-get($colors, primary-select-active);

$aspen-primary: (
  50: lighten($primary-color, 90%),
  100: lighten($primary-color, 80%),
  200: lighten($primary-color, 60%),
  300: lighten($primary-color, 40%),
  400: lighten($primary-color, 20%),
  500: $primary-color,
  600: darken($primary-color, 20%),
  700: darken($primary-color, 40%),
  800: darken($primary-color, 50%),
  900: darken($primary-color, 80%),
  A100: lighten($primary-color, 100%),
  A200: lighten($primary-color, 80%),
  A400: lighten($primary-color, 50%),
  A700: darken($primary-color, 50%),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(white, 0.87),
    700: rgba(white, 0.87),
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(white, 0.87),
  ),
);

$aspen-red: (
  50: lighten($warn-color, 90%),
  100: lighten($warn-color, 80%),
  200: lighten($warn-color, 60%),
  300: lighten($warn-color, 40%),
  400: lighten($warn-color, 20%),
  500: $warn-color,
  600: darken($warn-color, 20%),
  700: darken($warn-color, 40%),
  800: darken($warn-color, 50%),
  900: darken($warn-color, 80%),
  A100: lighten($warn-color, 100%),
  A200: lighten($warn-color, 80%),
  A400: lighten($warn-color, 50%),
  A700: darken($warn-color, 50%),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(white, 0.87),
    600: rgba(white, 0.87),
    700: rgba(white, 0.87),
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(white, 0.87),
  ),
);

.aspen {
  /* Button */

  @mixin mat-menu-base() {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mat-mdc-outlined-button {
    font-size: 1rem;
    border-radius: 0.5rem;
    line-height: 1.13;
    min-height: 3.5rem;
    min-width: 10.75rem;
    font-weight: 500;

    &:not(:disabled) {
      color: $primary-color;
      border: 0.125rem solid $primary-color;
    }

    &:hover {
      color: $primary-hover-color;
      border-color: $primary-hover-color;

      .mat-button-focus-overlay {
        opacity: 0;
      }
    }

    &.mat-primary {
      color: map-get($colors, grey-11);
      background-color: $primary-color;

      &:hover,
      .mat-button-focus-overlay {
        background-color: $primary-hover-color;
      }
    }

    &.mat-primary,
    &[disabled] {
      &[disabled] {
        color: map-get($colors, grey-6);
        background-color: map-get($colors, grey-9);
        border-color: map-get($colors, grey-9);
      }
    }

    /* TODO: handle important */
    &.small {
      max-width: 124px !important;
      max-height: 32px !important;
      min-height: 32px !important;
      min-width: 124px !important;
      padding: 0;
      font-size: 0.875rem;
      border-width: 2px;

      &.submit {
        width: 244px;
        max-width: 244px !important;
        padding: 0;
        font-size: 1rem;
        border-width: 2px;
        margin-right: 2rem;
        margin-left: 2rem;
      }

      &.cell-button {
        min-height: 2rem;
        max-width: 5.25rem !important;
        margin-right: 2.5rem;
        width: 5.25rem !important;
        min-width: 5.25rem !important;
      }

      &.submit-button {
        min-height: 2rem;
        max-width: 5.75rem !important;
        width: 5.75rem !important;
        min-width: 5.25rem !important;
      }

      &.action-button {
        min-height: 2.5rem !important;
        width: 11.25rem !important;
        min-width: 11.25rem !important;
      }
    }

    &.normal {
      min-height: 40px !important;
    }
  }

  .mat-mdc-autocomplete-panel {
    @include mat-menu-base();
    font-size: 0.875rem;
    color: map-get($colors, grey-7);
    background-color: black;
    visibility: hidden;
    max-width: none;
    position: relative;
    width: 100%;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .mat-mdc-option {
      font-size: 0.875rem;
      height: 2.25rem;
      line-height: 2.25rem;

      @media (max-width: $screen-sm-max) {
        padding-left: 9px !important;
        padding-right: 7px;
      }

      @media (min-width: $screen-md-min) {
        padding-left: 9px !important;
        padding-right: 6px;
      }

      &.mat-active {
        background: transparent;
      }

      &:hover {
        background-color: map-get($colors, grey-9);
      }

      &.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
        color: map-get($colors, white);
        background-color: $primary-select-color;
      }

      .mdc-list-item__primary-text {
        color: map-get($colors, white);
        white-space: nowrap;
      }

      min-height: unset;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mat-active):not(:hover) {
      &:not(.mat-mdc-option-disabled) {
        color: map-get($colors, white);
      }
    }

    .mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
    }

    &.mat-autocomplete-visible {
      visibility: visible;
      background: map-get($colors, black);
    }

    &.mat-autocomplete-hidden {
      visibility: hidden;
    }

    .mat-mdc-autocomplete-panel-above & {
      border-radius: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-right-width: 2px;
    }

    .mat-divider-horizontal {
      margin-top: -1px;
    }
  }

  /* Form Field */

  .mat-mdc-form-field {
    .mdc-text-field {
      padding: 0;
      height: 100%;

      .mdc-floating-label {
        top: 40%;
        color: map-get($colors, white);
        left: 0;
      }
    }

    // .mat-mdc-form-field-subscript-wrapper {
    //   display: none;
    // }
    letter-spacing: normal;

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
      font-weight: 400;
    }
  }

  .count-field-label {
    font-size: 12px;
  }

  .count-field-container {
    width: 378px;
    height: 66px;
    background: map-get($colors, black);

    span {
      font-size: 14px;
    }
  }

  .billing-code .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .count-field {
    // width: 376px;
    // height: 89px;
    margin: 0px 2px !important;
    .mat-mdc-form-field-infix {
      // padding: 13px 0px 13px 0px;
      padding: 0px !important;
      display: flex;
      align-items: center;
    }

    // .mat-select-arrow {
    //   border-left-width: 8px;
    //   border-right-width: 8px;
    //   border-top-width: 8px;
    //   width: 16px;
    //   height: 8px;
    //   right: -4px;
    //   position: absolute;
    //   margin: 0 0;
    // }

    // .mat-select-value-text {
    //   position: relative;
    //   left: calc(50% - 15px);
    // }

    // .mat-select-placeholder {
    //   padding-top: 0px;
    // }

    // .mdc-floating-label {
    //   top: 20%;
    // }

    .mdc-floating-label {
      top: 20%;
    }

    .mat-mdc-form-field-infix {
      width: unset;
    }

    .mat-mdc-text-field-wrapper {
      flex: none;
      height: unset;
    }

    .mat-mdc-form-field-flex {
      padding: 0px !important;
    }

    .mat-mdc-select-trigger {
      padding: 0 10px;
    }

    mat-select {
      width: 132px;
      height: 40px;
      padding: 10px 0 0 0;
      background-color: map-get($colors, grey-10);
      margin: 0 0;
    }

    // .monthly-close-due-on-mat-label {
    //   width: 151px;
    //   height: 14px;
    // }

    .filled {
      height: 40px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .no-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .monthly-fee-container,
  .next-bill-container {
    .mdc-floating-label {
      max-width: 200px;
      top: 30% !important;

      .monthly-fee-label,
      .next-bill-label {
        height: 24px;
        width: 200px;
      }
    }
  }

  .next-bill-container {
    .mdc-notched-outline {
      color: map-get($colors, grey-9) !important;
    }

    &:hover {
      .mdc-notched-outline > * {
        color: map-get($colors, grey-9) !important;
        border-width: 1px;
      }

      .mdc-floating-label {
        color: white !important;
      }
    }
  }

  .comment-field {
    .mdc-floating-label {
      top: 10% !important;
    }
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) {
    &.mat-warn {
      .mat-select-arrow {
        color: $warn-color;
      }

      .mat-select.mat-select-disabled .mat-select-arrow {
        color: map-get($colors, grey-6);
      }
    }

    &.mat-focused {
      .mat-mdc-form-field-label.mat-warn {
        color: map-get($colors, white);
      }
    }

    &:not(.mat-mdc-form-field-appearance-legacy)
      .mat-mdc-form-field-suffix
      .mat-mdc-icon-button {
      width: 1.5rem;
      height: 1.5rem;
    }

    .mdc-text-field {
      background-color: map-get($colors, black);
    }
  }

  .mat-mdc-form-field {
    &[floatlabel='always'] {
      margin-top: 20px;
    }
  }

  .mat-mdc-form-field:not(.with-hint) {
    .mat-mdc-form-field-subscript-wrapper {
      // display: none;
    }
  }

  .mat-mdc-form-field.with-hint {
    mat-hint {
      margin-top: 0.25rem;
      color: map-get($colors, red);
    }

    .mat-mdc-form-field-bottom-align::before {
      content: none;
    }

    .mat-mdc-form-field-hint-wrapper {
      padding: 0;
    }
  }

  .mat-mdc-form-field.mat-form-field-appearance-outline {
    .mat-mdc-form-field-wrapper {
      margin: 0.8px 0;
      padding-bottom: 16px;

      .mat-mdc-form-field-flex {
        margin-top: 0;
      }
    }

    .mat-mdc-form-field-infix {
      border-top-width: 0;
      padding: 10px 0;
      font-size: 14px;
      line-height: 20px;

      @media (max-width: $screen-sm-max) {
        position: relative;
      }
    }

    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix {
      top: 0.5rem;
    }

    .mat-mdc-form-field-flex {
      padding-left: 11px;
      padding-right: 11px;

      @media (max-width: $screen-sm-max) {
        padding-left: 9px;
        padding-right: 9px;
      }
    }

    .mdc-notched-outline {
      top: 0;

      &-gap {
        display: none;
      }

      &__leading {
        width: 0;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled):not(.has-trigger):not(
      .read-only
    ).mat-form-field-appearance-outline {
    .mdc-notched-outline {
      opacity: 1;
      color: map-get($colors, black);
    }

    &.mat-focused,
    &:hover {
      .mdc-notched-outline > * {
        color: $primary-color;
        border-width: 1px;
      }

      .mdc-notched-outline__notch {
        border-top: 1px solid $primary-color;
      }

      &.mat-warn {
        .mat-mdc-form-field-outline-thick {
          color: $warn-color;
        }
      }

      .mdc-floating-label {
        // color: $primary-color;
        // top: 40%;
      }
    }

    &:not(.mat-focused):hover {
      .mdc-notched-outline,
      .mat-select-arrow {
        color: $primary-hover-color;
      }

      .mdc-notched-outline__notch {
        border-top: 1px solid $primary-color;
      }

      .mat-select-value {
        color: map-get($colors, grey-6);
      }

      .mdc-floating-label {
        // color: $primary-color;
        // top: 40%;
      }
    }

    &.mat-mdc-form-field-can-float {
      .mat-mdc-form-field-label,
      .mat-input-server:focus
        + .mat-mdc-form-field-label-wrapper
        .mat-mdc-form-field-label {
        transform: none;
      }
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0);
    }
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled):not(.has-trigger):not(
      .read-only
    ).mat-form-field-appearance-outline.mat-form-field-invalid:not(
      .mat-focused
    ) {
    .mdc-notched-outline > * {
      color: $warn-color;
    }

    .mdc-notched-outline > .mdc-notched-outline__notch {
      border-top: 1px solid $warn-color;
    }
  }

  .mat-mdc-form-field.mat-form-field-disabled.mat-form-field-appearance-outline {
    .mdc-notched-outline {
      opacity: 1;
      color: map-get($colors, grey-9);
    }

    .mdc-text-field {
      background-color: map-get($colors, grey-9);
    }
  }

  .mat-mdc-form-field.has-trigger {
    .mdc-text-field {
      background-color: transparent;
      padding: 0;
    }

    .mdc-notched-outline {
      opacity: 1;
      color: transparent;
    }

    &.mat-focused,
    &:hover {
      .mdc-notched-outline > * {
        color: transparent;
        border-width: 1px;
      }

      &.mat-warn {
        .mat-mdc-form-field-outline-thick {
          color: transparent;
        }
      }
    }

    .mat-mdc-select-trigger {
      width: unset;

      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }
  }

  .mat-mdc-optgroup-label {
    position: sticky !important;
    z-index: 1 !important;
    top: 0 !important;
    background: map-get($colors, grey-10) !important;
    color: map-get($colors, white);
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    padding-left: 10px;
    min-height: unset;
  }

  /* Input */
  .mat-mdc-input-element {
    @include input-placeholder {
      color: $placeholder-color !important;
      -webkit-text-fill-color: $placeholder-color;
    }

    color: map-get($colors, white);
  }

  .mat-warn .mat-mdc-input-element,
  .mdc-text-field--focused .mat-mdc-input-element {
    caret-color: $primary-color;
  }

  .mat-focused,
  .mat-warn,
  .mat-mdc-form-field-hide-placeholder {
    .mat-mdc-input-element {
      @include input-placeholder {
        color: $placeholder-color !important;
        -webkit-text-fill-color: $placeholder-color;
      }
    }
  }

  /* Snackbar */
  .cdk-overlay-container .mat-mdc-snack-bar-container {
    min-height: unset;

    .mdc-snackbar__surface {
      box-shadow: unset;
      max-width: 100%;
      width: 100%;
    }
  }

  /* Select */

  .mat-mdc-select {
    &-arrow svg {
      width: 36px;
      height: 36px;
      left: 25%;
    }

    &-trigger {
      height: 1.25rem;
    }

    &-placeholder {
      color: $placeholder-color;
    }

    &-disabled .mat-select-value {
      color: map-get($colors, grey-6);
    }
  }

  .mat-mdc-select:not(.mat-mdc-select-disabled) {
    .mat-mdc-select-arrow {
      color: $primary-color;
    }
  }

  .mat-mdc-select.mat-mdc-select-disabled {
    .mat-mdc-select-arrow {
      color: #ffffff80;
    }
  }

  /* mat-selection-list */
  .mat-mdc-selection-list {
    .mat-mdc-list-option {
      .mdc-list-item__start {
        margin: 0 5px;
      }

      .mdc-list-item__content {
        height: 40px;
        line-height: 40px;

        .mat-mdc-list-item-unscoped-content {
          display: inline-block;
          max-width: 530px;
        }
      }
    }

    .mat-mdc-list-item {
      height: 48px;
      display: flex !important;
      align-items: center;
    }

    .mat-mdc-list-item.not-show {
      display: none !important;
    }
  }

  .mat-mdc-list-option,
  .mat-mdc-checkbox,
  .mat-mdc-checkbox:hover {
    .mdc-checkbox
      .mdc-checkbox__native-control:enabled
      ~ .mdc-checkbox__background
      .mdc-checkbox__checkmark {
      color: $primary-color;
      border-color: #ffffffb3;
      border: none;
      border-radius: 3px;
    }

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      border-color: #ffffffb3;
      border: 1px solid;
      border-radius: 3px;
    }

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      background-color: transparent;
      border-color: #ffffffb3;
    }

    .mdc-checkbox .mdc-checkbox__background {
      border: 1px solid;
      border-color: #ffffffb3;
      border-radius: 3px;
    }
  }

  .mat-mdc-list-option
    .mdc-checkbox
    .mdc-checkbox__native-control[disabled]:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[disabled]:checked
    ~ .mdc-checkbox__background {
    background: map-get($colors, grey-15);
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[disabled]:checked
    ~ .mdc-checkbox__background {
    opacity: 0.38;
  }

  .mat-mdc-select-placeholder {
    color: $placeholder-color !important;
    -webkit-text-fill-color: $placeholder-color;
    font-weight: 400;
  }

  .mat-focused,
  .mat-warn,
  .mat-mdc-form-field-hide-placeholder {
    .mat-mdc-select-placeholder {
      color: $placeholder-color !important;
      -webkit-text-fill-color: $placeholder-color;
    }
  }

  .bk-task-list-select-user-panel {
    min-width: 350px;

    mat-option {
      height: 40px !important;
      line-height: 20px !important;

      .user-info {
        @include text-ellipsis();
      }

      .user-type {
        color: map-get($colors, grey-7);
      }
    }
  }

  /* Datepicker */

  .mat-datepicker-toggle {
    color: $primary-color;

    .mat-mdc-icon-button {
      width: 20px;
      height: 100%;

      .mat-icon {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .mat-date-range-input-wrapper {
    max-width: unset;
  }

  /* Dialog */

  @mixin dynamic-layout($is-off: true) {
    @if $is-off {
      margin-top: 0rem;
    } @else {
      margin-top: 2.625rem;
    }
  }

  .mat-mdc-dialog-container {
    padding: 20px;
    position: relative;
    border-radius: 0;
    overflow: hidden;
    background-color: map-get($colors, grey-10);

    .mdc-dialog__content {
      color: unset;
      padding: 0;

      .mat-mdc-select {
        color: map-get($colors, white);
      }

      .mdc-notched-outline__notch {
        width: 100% !important;
      }

      font-family: $font-family;
      letter-spacing: normal;
    }

    .mdc-dialog__surface {
      box-shadow: unset;
      background-color: unset;
      overflow-y: unset;
    }

    .mdc-floating-label {
      left: 0;
      color: map-get($colors, white);
    }

    .mat-mdc-icon-button {
      text-align: right;
    }

    .mat-mdc-icon-button.invite-member-add {
      width: 311px;
      text-align: left;
    }

    .mat-mdc-icon-button,
    .mdc-icon-button {
      .icon-circle-minus,
      .icon-close {
        &::before {
          font-size: 1.25rem;
          color: $primary-color;
        }
      }

      .icon-circle-plus {
        &::before {
          font-size: 1.5rem;
        }
      }

      .icon-circle-plus {
        margin-right: 0.5rem;
      }

      &:not([disabled]) {
        .icon-close,
        .icon-circle-minus,
        .icon-circle-plus {
          &::before {
            color: map-get($colors, primary);
            transition: 0.3s ease;
          }
        }
      }

      &:not([disabled]):hover {
        .icon-close,
        .icon-circle-minus,
        .icon-circle-plus {
          &::before {
            color: map-get($colors, primary-hover);
          }
        }
      }

      &[disabled],
      &[disabled]:hover {
        .icon-close,
        .icon-circle-minus,
        .icon-circle-plus {
          &::before {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }

    .mat-mdc-dialog-title {
      font-size: 1.75rem;
      line-height: 1.29;
      font-weight: 500;
      margin-bottom: 16px;
      padding: 0;
      color: white;
    }

    .mat-dialog-content {
      margin: 0;
      padding: 0;
      margin-right: -1.25rem;
      padding-right: 1.25rem;

      @media (max-width: $screen-sm-max) {
        max-height: unset;
      }
    }

    .mat-mdc-dialog-actions {
      justify-content: flex-end;
      padding: 0;
      min-height: unset; // rewrite initial mat
      margin-bottom: 0;

      &.btn-wrapper {
        &.on {
          @include dynamic-layout($is-off: false);
        }

        &.off {
          @include dynamic-layout($is-off: true);
        }
      }

      @media (max-width: $screen-sm-max) {
        justify-content: center;
      }

      .mat-mdc-outlined-button {
        width: 11.5rem;
        min-width: unset;
        min-height: 2.5rem;

        max-width: 11.5rem;
        flex: 1 1 0;

        & + .mat-mdc-outlined-button {
          margin-left: 1.5rem;

          @media (max-width: $screen-sm-max) {
            // margin-left: 0;
          }
        }
      }
    }

    [mat-dialog-close] {
      position: absolute;
      top: 0rem;
      right: 0rem;

      .mat-icon {
        font-size: 1.75rem;
        width: 1.75rem;
        height: 1.75rem;
        line-height: 1.75rem;
      }
    }

    .gap {
      margin-bottom: 16px;
    }

    .business-function {
      .mdc-floating-label {
        max-width: 200px;
      }
    }
  }

  .gap-bottom {
    margin-bottom: 16px;
  }

  .cdk-overlay-container {
    .cdk-overlay-dark-backdrop {
      background: $cdk-overlay-dark-backdrop;
    }

    .mat-mdc-menu-panel {
      border-radius: 0;
      min-height: auto;
      background: map-get($colors, grey-9);
    }

    .mat-mdc-select-panel {
      background-color: map-get($colors, black);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 0;
      padding-bottom: 0;

      &-wrap {
        margin-top: 1.625rem;

        &:has(> div.customer-form-select) {
          margin-top: unset;
        }

        @media (max-width: $screen-sm-max) {
          margin-left: 7px;
          padding-right: 7px;
        }

        @media (min-width: $screen-md-min) {
          margin-left: 6px;
          padding-right: 6px;
        }
      }

      .mat-mdc-option {
        height: 2.25rem;
        line-height: 2.25rem;
        font-size: 0.875rem;

        .mdc-list-item__primary-text {
          width: 100%;
        }

        @media (max-width: $screen-sm-max) {
          padding-left: 9px;
          padding-right: 7px;
        }

        @media (min-width: $screen-md-min) {
          padding-left: 9px;
          padding-right: 6px;
        }

        &.mat-active {
          background: transparent;
        }

        &:hover {
          background-color: map-get($colors, grey-9);
        }

        &.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
          color: map-get($colors, white);
          background-color: $primary-select-color;
        }

        min-height: unset;

        .mdc-list-item__primary-text {
          @include text-ellipsis();
        }
      }

      &.mat-primary {
        border: 1px solid $primary-color;
        // lacking of border-top looks like sth is cutoff
        // border-top: 0;
        color: white;
      }

      &.mat-warn {
        border: 1px solid $warn-color;
        border-top: 0;
      }
    }

    .mat-mdc-autocomplete-panel {
      border: 1px solid $primary-color;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: none;
      margin-top: -3px;
      padding: unset;
    }

    .nav-tenant-panel {
      border-top: 1px solid $primary-color;
    }

    .mat-mdc-autocomplete-panel-above .mat-mdc-autocomplete-panel {
      border: 1px solid $primary-color;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      margin-bottom: -3px;
    }

    .cdk-overlay-pane .mat-mdc-snack-bar-container {
      padding: 0;
      width: 44.5rem;
      max-width: none;
      margin-bottom: 2rem;

      @media (max-width: $screen-sm-max) {
        margin: 1.5rem;
      }

      app-toast {
        .toast-content {
          color: map-get($colors, grey-11);
          padding: 0.5625rem 1.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          &-success {
            background-color: transparentize(map-get($colors, green), 0.1);
          }

          &-fail {
            background-color: transparentize($warn-color, 0.1);
          }

          &-warn {
            background-color: transparentize(map-get($colors, orange), 0.1);
          }

          &-pending {
            background-color: transparentize(map-get($colors, grey-5), 0.1);
          }

          &-info {
            background-color: transparentize(map-get($colors, grey-10), 0.1);
          }

          &-none {
            display: none;
          }

          @media (max-width: $screen-sm-max) {
            padding: 1rem;
          }

          &-info .toast-message {
            color: map-get($colors, white);
          }

          .toast-message {
            flex: 1;
            text-align: left;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: 0.4px;

            @media (max-width: $screen-sm-max) {
              letter-spacing: 0.35px;
            }

            .font-bold {
              font-weight: bold;
            }
          }

          .toast-cancel {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.5;
            margin-right: 1.25rem;
          }

          .icon-alert,
          .icon-confirmed,
          .icon-moderate-alert {
            &::before {
              font-size: 1.5rem;
              color: map-get($colors, black);
              margin-right: 0.75rem;

              @media (max-width: $screen-sm-max) {
                margin-right: 1rem;
              }
            }
          }

          .icon-information-fill {
            &::before {
              font-size: 1.5rem;
              color: map-get($colors, appkit-blue);
              margin-right: 0.75rem;

              @media (max-width: $screen-sm-max) {
                margin-right: 1rem;
              }
            }
          }

          .icon-close {
            &::before {
              font-size: 1.25rem;
              color: map-get($colors, black);
            }
          }

          &-info .icon-close {
            &::before {
              color: map-get($colors, white);
            }
          }

          .icon-close:hover {
            &::before {
              object-fit: contain;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .display-hide {
      display: none;
    }

    .template-options {
      .mdc-list-item__primary-text {
        width: 100%;
      }
    }

    .mdc-menu-surface.mat-mdc-select-panel {
      max-height: 300px;
    }

    .panelPosition {
      transform: translateX(-160px) translateY(-10px) !important;

      .mat-mdc-select-panel-wrap {
        margin-top: 5px !important;
      }
    }

    .bk-task-list-select-user-tooltip {
      max-width: 300px;
    }
  }

  .mat-expansion-panel {
    border-radius: 0;
    background-color: map-get($colors, grey-10);
    box-shadow: none;
    margin: 0 -1.25rem;
    padding: 0;

    .mat-expansion-panel-header {
      border-bottom: 1px solid map-get($colors, grey-7);
      padding: 0 1.25rem;

      .mat-expansion-panel-header-title {
        font-size: 1.125rem;
      }

      .mat-expansion-indicator {
        &::after {
          padding: 0.4688rem;
          color: $primary-color;
          margin-top: -0.6875rem;
        }
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        background-color: map-get($colors, grey-11);
        font-size: 1.125rem;
        padding: 0.75rem 1.25rem;
      }
    }

    &:not(.mat-expanded) {
      .mat-expansion-panel-header:hover {
        background-color: map-get($colors, grey-10);
      }

      &:first-child {
        .mat-expansion-panel-header {
          border-bottom: 1px solid map-get($colors, grey-7);
        }
      }
    }

    &.mat-expanded {
      .mat-expansion-panel-content {
        border-bottom: 1px solid map-get($colors, grey-7);
      }
    }
  }

  /* Tabs */
  .mat-tab {
    &-group {
      .mat-ink-bar {
        height: 0.25rem;
      }
    }

    &-header {
      border-bottom: none;
      margin-bottom: 0.25rem;
    }

    &-label {
      &-active {
        opacity: 1;
      }

      height: 2.75rem;
      padding-left: 0;
      padding-right: 0;
      min-width: 0;
      opacity: 0.7;
      color: map-get($colors, white);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;

      @media (min-width: $screen-md-min) {
        + .mat-tab-label {
          margin-left: 2rem;
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      &-group {
        .mat-ink-bar {
          display: none;
        }
      }

      &-header {
        margin-bottom: 1rem;
      }

      &-labels {
        > .mat-tab-label {
          &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            border-left: 1px solid $primary-color;
          }

          &:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }

      &-body-content {
        overflow-x: hidden;
      }

      &-label {
        &-active {
          background-color: $primary-color;
          color: map-get($colors, grey-10);
        }

        font-weight: 500;
        opacity: 1;
        flex-basis: 0;
        flex-grow: 1;
        border-top: 1px solid $primary-color;
        border-bottom: 1px solid $primary-color;
        border-right: 1px solid $primary-color;
        color: $primary-color;
        background: transparent;
        text-transform: uppercase;
        border-collapse: collapse;
        transition: color 0.3s ease, background 0.3s ease;
      }
    }
  }

  /* Tooltip */
  .mat-mdc-tooltip {
    border-radius: 2px;
    background: map-get($colors, grey-9);
    position: relative;
    overflow: initial;
    // margin-top: 1rem;
    font-size: 12px;
    padding: 10px 18px;
    max-width: 3000px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    &::after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid map-get($colors, grey-9);
      top: -8px;
      right: calc(50% - 8px);
    }

    &.tooltip-edge::after {
      @media (min-width: 1300px) and (max-width: 1500px) {
        right: var(--tooltipEdgeOffset);
      }

      @media (min-width: 815px) and (max-width: 1300px) {
        right: 2%;
      }
    }

    &.account::after {
      @media (max-width: ($screen-md-min + 1)) {
        right: 2%;
      }
    }

    &.toolbar::after {
      @media (min-width: 1300px) and (max-width: 1500px) {
        right: var(--tooltipOffset);
      }

      @media (min-width: 815px) and (max-width: 1300px) {
        right: 30%;
      }
    }

    &.customer-detail::after {
      @media (min-width: 1350px) and (max-width: 1440px) {
        right: var(--customerDetailOffset);
      }

      @media (max-width: 1349px) {
        right: 2%;
      }
    }

    & .mdc-tooltip__surface {
      color: unset;
      background: unset;
      max-width: unset;
    }
  }

  .customer-table-toolbar {
    & .mdc-tooltip__surface {
      text-align: left;
    }
  }

  .mat-mdc-tooltip-panel-right {
    .mat-mdc-tooltip::after {
      border-left: 8px solid transparent;
      border-right: 8px solid map-get($colors, grey-9);
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      top: 12px;
      left: -16px;
    }
  }

  .mat-mdc-tooltip-panel-left {
    .mat-mdc-tooltip::after {
      border-left: 8px solid map-get($colors, grey-9);
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      top: 12px;
      right: -16px;
    }
  }

  .mat-mdc-tooltip-panel-above {
    .mat-mdc-tooltip::after {
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid map-get($colors, grey-9);
      border-bottom: 8px solid transparent;
      top: calc(100%);
      right: calc(50% - 8px);
    }
  }

  table.mat-mdc-table {
    width: 100%;
    background: map-get($colors, grey-12);

    .mat-mdc-header-row {
      height: 48px;

      th {
        vertical-align: middle;
        font-size: 14px;
        border-bottom: none;
        color: map-get($colors, white);
        padding-right: 1.5rem;
      }

      // th:hover .resize-handle {
      //   opacity: 1;
      //   transition: .3s ease-in-out;
      // }

      .resize-handle {
        display: inline-block;
        border-right: 1px solid map-get($colors, grey-18);
        position: absolute;
        top: 16px;
        right: 10px;
        height: 16px;
        cursor: col-resize;
      }

      .resize-handle:hover {
        width: 20px;
      }

      .mdc-data-table__header-cell {
        text-overflow: unset;
      }

      background: map-get($colors, grey-10);
    }

    .mat-mdc-row {
      height: 72px;

      td {
        vertical-align: middle;
        font-size: 14px;

        a {
          color: $primary-color;
        }

        .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
          padding-bottom: 0;
        }
      }

      td:not(:last-child) {
        padding-right: 1.5rem;
      }

      td:last-child {
        padding-right: 0;
      }

      td.mat-mdc-cell {
        border-bottom: 1px solid map-get($colors, grey-9);
      }

      td[class^='mat-column-'],
      td[class*='mat-column-'] {
        @include text-ellipsis();

        &:has(mat-checkbox) {
          text-overflow: unset;
        }
      }

      td.active-link {
        @include text-wrap-ellipsis-active();
      }

      em.icon-kebab {
        font-size: 1.5rem;

        &:hover::before {
          color: map-get($colors, primary-hover);
        }
      }

      em.icon-kebab.selected::before {
        color: map-get($colors, primary);
      }
    }

    tr.mat-mdc-no-data-row {
      height: 72px;

      td.no-data-available {
        text-align: center;
        vertical-align: middle;
        color: map-get($colors, grey-7);
        border-bottom: 1px solid map-get($colors, grey-9);
      }

      td.no-data-pic-container {
        text-align: center;
      }
    }

    .mat-mdc-row:not(.mat-mdc-no-data-row) {
      &:hover {
        background-color: map-get($colors, black);
      }
    }

    tr.row-activated {
      background-color: map-get($colors, black);
    }

    tr.row-activated > td:first-child {
      box-shadow: inset 6px 0px 0px 0px map-get($colors, primary);
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0;
    }

    th.mat-mdc-header-cell:first-of-type,
    td.mat-mdc-cell:first-of-type,
    td.mat-mdc-footer-cell:first-of-type {
      padding-left: 24px;
    }

    .mat-mdc-table-sticky-border-elem-left {
      border-right: 1px solid map-get($colors, grey-9);
    }

    .mat-mdc-table-sticky-border-elem-right {
      border-left: 1px solid map-get($colors, grey-9);
    }

    .mat-mdc-form-field:not(.with-hint) {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  .mat-table-container {
    max-width: 100%;
    // box-shadow: none;
    overflow: auto;
  }

  .mdc-data-table__table {
    min-width: unset;
  }

  .customer-table-toolbar {
    white-space: pre-line;
  }

  .mat-checkbox-frame {
    border-color: white;
    border-width: 1px;
    border-radius: 4px;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0 !important;
    background-color: transparent !important;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    background-color: transparent;
    border-color: white;
  }

  .mat-checkbox-checkmark-path,
  .mdc-checkbox__checkmark-path {
    stroke: $primary-color !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    border-color: $primary-color;
  }

  .mat-checkbox-indeterminate .mat-checkbox-mixedmark {
    background-color: $primary-color !important;
  }

  .mat-checkbox-disabled .mat-checkbox-inner-container {
    background-color: map-get($colors, grey-5);
    opacity: 0.25;
    border-radius: 5px;
  }

  .mat-checkbox-disabled .mat-checkbox-background {
    background-color: transparent;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[disabled]:not(:checked):not(
      :indeterminate
    ):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    border-color: map-get($colors, grey-5);
    background-color: map-get($colors, grey-5);
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    background-color: transparent;
    border-color: white;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle,
  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle,
  .mat-mdc-radio-button
    .mdc-radio:not(:disabled):active
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: $primary-color;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: #ffffffb3;
  }

  .icon-info::before {
    color: $primary-color;
  }

  .pre-line {
    white-space: pre-line;
  }

  /*Card*/
  .mat-mdc-card {
    .mat-mdc-card-title {
      font-size: 24px;
    }
  }
}

.bulk-button-wrapper {
  .ap-buttons-wrapper .ap-menu-buttons .ap-button {
    &:not([disabled]).ap-button-primary {
      color: map-get($colors, black);
      background-color: map-get($colors, primary);
    }

    &:disabled {
      color: map-get($colors, grey-6);
      background-color: map-get($colors, grey-9);
      border-color: map-get($colors, grey-9);
    }
  }

  .ap-option-item.active {
    background-color: unset;
  }

  .ap-buttons-wrapper .ap-button-dropdown {
    z-index: 102 !important;
    width: unset !important;
    border-radius: 3px !important;
    border: 1px solid map-get($colors, primary);
    background: black !important;

    .ap-option-label {
      color: white;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

// empty message display
.empty-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 5.5rem;
  border-bottom: 1px solid #424242;

  .empty-message {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #757575;
  }
}

.checkbox-container {
  .mat-pseudo-checkbox,
  .mat-checkbox-frame,
  .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border: 1px solid;
    border-radius: 3px;
  }

  .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-checked {
    background-color: transparent;
    border: 1px solid;
  }

  .mat-pseudo-checkbox-checked::after {
    left: 2px;
    top: 3px;
  }

  .mat-pseudo-checkbox::after {
    color: $primary-color;
  }

  .mat-checkbox-disabled .mat-checkbox-inner-container {
    background-color: map-get($colors, grey-5);
    opacity: 0.25;
    border-radius: 3px;
  }

  .mat-checkbox-disabled .mat-checkbox-background {
    background-color: transparent;
  }
}

.mat-mdc-list-option
  .mdc-checkbox
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  background-color: #bdbdbd !important;
}

.table-header-filter {
  margin-top: 7px;
  width: 30px !important;

  .mat-mdc-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mat-mdc-form-field-flex {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mat-mdc-form-field-outline-start,
  .mat-mdc-form-field-outline-end {
    background-color: initial !important;
  }

  .mat-mdc-form-field-outline-thick {
    color: transparent !important;
  }

  .mat-mdc-form-field-outline {
    color: transparent !important;
  }

  .mat-select-arrow {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    color: transparent;
  }
}

.table-header-filter-panel {
  border: 1px solid $primary-color !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  min-width: 175px !important;

  .mat-mdc-option.mat-mdc-option-disabled.mdc-list-item--selected {
    color: white;
    background-color: $primary-color !important;
  }
}

.icon-icon-outline-filter,
.icon-icon-fill-filter {
  font-size: 20px;
}

.text-url {
  cursor: pointer;
  color: map-get($colors, primary);
  text-decoration: underline;

  &:hover {
    color: map-get($colors, primary-hover);
  }
}

.img-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.na-value {
  color: map-get($colors, grey-9);
}

button.remove-button {
  background: transparent;
  border: none;

  .icon-circle-minus:before {
    color: map-get($colors, primary);
    font-size: 1rem;
  }
}
