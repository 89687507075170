.charts {
  fill: map-get($colors, grey-5);

  &-container {
    position: relative;
    overflow: hidden;
  }

  &-body {
    [ngx-charts-x-axis],
    [ngx-charts-y-axis] {
      line.gridline-path {
        stroke: map-get($colors, grey-8);
      }
    }

    [ngx-charts-y-axis] {
      fill: transparent;
    }
  }

  &-y-axis,
  &-y-right-axis {
    position: absolute;
    top: 0;
    pointer-events: none;
    background-color: map-get($colors, grey-10);
  }

  &-y-axis {
    left: 0;
  }

  &-y-right-axis {
    right: 0;
    height: 100%;

    .ngx-charts-outer {
      position: absolute;
      right: 0;
    }
  }

  .x.axis > g {
    transform: translate(0, -0.5rem);
  }

  .bottom .x.axis > g {
    transform: translate(0, 12px);
  }

  .left .y.axis {
    [ngx-charts-axis-label] {
      transform: translate(6px, 0);
    }

    [ngx-charts-y-axis-ticks] .tick text {
      transform: translate(8px, 0);
    }
  }

  .right .y.axis {
    [ngx-charts-axis-label] {
      transform: translate(0, 0);
    }

    [ngx-charts-y-axis-ticks] .tick text {
      transform: translate(4px, 0);
    }
  }

  .tick text {
    font-size: 0.875rem !important;
  }

  .line-chart {
    .dash {
      stroke-dasharray: 3 6;
    }

    .line-highlight {
      opacity: 0;
    }

    .line {
      stroke-width: 2;
      stroke-linecap: round;
    }
  }

  &-pointer-events-none {
    &,
    & * {
      pointer-events: none;
    }
  }
}

.tooltip-anchor {
  fill: map-get($colors, black);
}

.legend {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0.25rem;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 0.75rem;
    }
  }

  &-entry {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1.25rem;
  }

  &-box {
    width: 0.5rem;
    height: 1.25rem;
    padding-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &-line {
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  &-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.aspen {
  .ngx-charts {
    .arc {
      &.active,
      &:hover {
        opacity: 1;
        transition: none;
      }
    }
  }

  .ngx-charts-tooltip-content.type-tooltip {
    font-size: 1rem;
    box-shadow: $box-shadow-tooltip;
    background-color: map-get($colors, black);
    padding: .5rem 1.25rem;

    .tooltip-caret {
      box-shadow: $box-shadow-tooltip;
      &.position-top {
        border-left-width: .5rem;
        border-right-width: .5rem;
        border-top: .5rem solid map-get($colors, black);
      }
      &.position-bottom {
        border-left-width: .5rem;
        border-right-width: .5rem;
        border-bottom: .5rem solid map-get($colors, black);
      }
      &.position-left {
        border-top-width: .5rem;
        border-bottom-width: .5rem;
        border-left: .5rem solid map-get($colors, black);
      }
      &.position-right {
        border-top-width: .5rem;
        border-bottom-width: .5rem;
        border-right: .5rem solid map-get($colors, black);
      }
    }
  }
}

.tooltip-content {
  &-item {
    display: flex;
    line-height: 2rem;
  }

  &-legend {
    width: .625rem;
    height: .625rem;
    margin-right: .5rem;
    margin-top: .6875rem;
    margin-bottom: .6875rem;
  }

  &-label {
    flex: 1;
    margin-right: 1.25rem;
    text-align: left;
  }

  &-value {
    color: map-get($colors, primary);
  }
}
