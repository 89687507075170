$primary-color: map-get($colors, primary);
.aspen .ngx-datatable {
  margin-top: 1.25rem;

  &.show-summary-cell {
    .datatable-summary-row {
      display: block;
    }

    .datatable-header {
      background-color: map-get($colors, grey-11);
      width: 861px;
      position: absolute;
      z-index: 1000;
      top: 72px;

      &-inner {
        width: 100% !important;
      }

      .common-datatable-header {
        margin-right: 2rem;
      }
    }

    .datatable-header-cell {
      .datatable-header-cell-template-wrap {
        font-size: 0.875rem;
        height: 48px; // datatable need static px
        line-height: 48px;
        cursor: pointer;
      }
    }
  }

  .datatable-summary-row {
    display: none;
    padding: 0 1.25rem;
    background: map-get($colors, grey-9);
    width: calc(100% + 2.5rem);

    .datatable-body-cell {
      line-height: 72px !important; // need px;
      overflow-y: hidden;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  .datatable-header {
    box-shadow: $database-scrollable-header-shadow;

    background-color: map-get($colors, grey-10);

    @media (max-width: $screen-sm-max) {
      box-sizing: content-box;
    }

    .mat-mdc-form-field {
      @media (max-width: $screen-sm-max) {
        width: 12.75rem;
      }
    }

    .datatable-for-mobile {
      position: relative;
      padding: 0 1.5rem;

      .mat-mdc-form-field {
        @media (max-width: $screen-sm-max) {
          position: absolute;
          right: 13.5rem;
          &.mobile-header-search-no-filter {
            right: 7.5rem;
          }
          left: 1.5rem;
          width: auto;
          max-width: none;
        }
      }
    }

    .mobile-filter-option,
    .mobile-sort-option {
      display: none;
      color: $primary-color;
      background-color: map-get($colors, black);
      border-radius: .1875rem;
      font-size: 1rem;
      width: 5.5rem;
      cursor: pointer;
      line-height: 2.5;
      margin-top: .5rem;
      margin-bottom: 1.5rem;
      height: 2.5rem;
      text-align: center;

      @media (max-width: $screen-sm-max) {
        display: block;
        float: right;
      }
    }

    .mobile-filter-option {
      margin-left: .5rem;
      margin-right: 0;
      text-align: center;
    }

    .mobile-sort-option {
      margin-left: .5rem;
    }
  }

  .datatable-body {

    .datatable-row-wrapper {
      .datatable-body-row {
        box-sizing: content-box;
        padding: 0 1.25rem;
        border-bottom: 1px solid map-get($colors, grey-9);
        transition: background-color .3s ease;

        @media (max-width: $screen-sm-max) {
          padding: 0;
        }

        .datatable-body-cell {
          font-size: 0.875rem;

          @media (max-width: $screen-sm-max) {
            padding-left: 1.5rem;
            padding-right: 1rem;
            font-size: .878rem;
          }

          @media (min-width: $screen-md-min) {
            line-height: 3.75rem;
          }

          @include text-ellipsis();

          span {
            display: inline-block;

            &.cell-seperate-num {
              width: 4.5rem;
            }

            &.cell-seperate-percent {
              width: 2.5rem;
              margin-right: 1rem;
            }
          }

          .netIncome-detail-client {
            height: 3rem;
            line-height: 3rem;
            margin-right: 2rem;
            @include text-ellipsis();

            .num-ytd-space {
              margin-right: 0.5rem;
            }
          }
        }

        &:hover {
          background-color: black;
          .icon-chevron-right {
            display: inline-block;
          }
        }

        &.active {
          background-color: rgba(49, 49, 49, 0.5);
        }

        em.icon-kebab {
          font-size: 1.5rem;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          right: 1.25rem;
          cursor: pointer;

          &.selected {
            &::before {
              color: $primary-color;
            }
          }
          &-user{
            @media (max-width: $screen-sm-max) {
              right: -.9rem !important;
            }
          }

          @media (max-width: $screen-sm-max) {
            right: 0.1rem;
          }
        }

        .datatable-dot {
          width: .5rem;
          height: .5rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;

          &-red {
            background: map-get($colors, red);
          }

          &-green {
            background: map-get($colors, green);
          }

          &-yellow {
            background: map-get($colors, orange);

          }

          &-blue {
            background: map-get($colors, azure);
          }

          &-grey {
            background: map-get($colors, grey-7);
          }

          &-purple {
            background: map-get($colors, purple);
          }
        }
      }
    }
  }


    .datatable-header {
      background-color: transparent;

      @media (max-width: $screen-sm-max) {
        height: auto !important;
      }

      @media (min-width: $screen-md-min) {
        background-color: map-get($colors, grey-10);
      }
    }
    .row-activated {
      box-shadow: inset 6px 0px 0px 0px $primary-color;
      background-color: black;
      .icon-chevron-right {
        display: inline-block;
      }
    }
    .none-selected {
      position: relative;
    }

  &.bookkeeper-dashboard  {
    @media (max-width: $screen-sm-max) {
      margin: 0 -1.5rem;
    }

    .datatable-header {
      background-color: transparent;

      @media (max-width: $screen-sm-max) {
        height: auto !important;
      }

      @media (min-width: $screen-md-min) {
        background-color: map-get($colors, grey-10);
        padding: 1rem 1.25rem;
      }

      .common-datatable-header {
        padding: 0;
        line-height: 1rem;
      }

      .bookkeeper-dashboard-header {
        position: relative;

        @media (max-width: $screen-sm-max) {
          padding: 0 1.5rem;
        }

        mat-form-field {
          @media (max-width: $screen-sm-max) {
            position: absolute;
            right: 13.5rem;
            left: 0;
            margin-left: 1.5rem;
            width: auto;
          }
        }
        .search{
          @media (max-width: $screen-sm-max) {
            position: relative;
            top: 0.3rem;
            margin-right: .64rem;
          }
        }
      }
    }

    .datatable-body {
      @media (max-width: $screen-sm-max) {
        border-top: 0.0625rem solid map-get($colors, grey-9);
      }
    }

    .datatable-row-wrapper .datatable-body-row .datatable-body-cell {
      @media (max-width: $screen-sm-max) {
        padding: 1.25rem 1.5rem;
      }
    }
  }

  .mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-flex {
      height: 2.25rem;
      @media (max-width: $screen-sm-max) {
        height: 2.5rem;
      }
      padding-left: .625rem;
      padding-right: .625rem;
    }

    .mat-mdc-form-field-infix {
      padding-top: .5rem;
      padding-bottom: .5rem;

      @media (max-width: $screen-sm-max) {
        padding-top: 7.5px; // need px
        padding-right: 7.5px;

      }
    }
  }

  .mat-mdc-form-field {
    width: 100%;

    @media (min-width: $screen-md-min) {
      max-width: 11.25rem;
    }
  }

  .datatable-footer-inner {
    position: relative;
  }

  app-datatable-pagination .datatable-pagination {
    @media (max-width: $screen-sm-max) {
      right: unset;

      left: 50%;
      transform: translateX(-50%);
    }
  }
}

::ng-deep .cdk-overlay-container {
  .cdk-overlay-pane {
    .action-dialog {
      margin-top: .5rem;
      margin-right: 1.25rem;
      border-radius: 0;
      overflow: hidden;

      .mat-mdc-menu-content {
        padding: 1rem 0;

        .mat-mdc-menu-item {
          height: 1.3125rem;
          font-size: 0.875rem;
          line-height: 1.5;
          color: $primary-color;
          min-height: unset;
          &:hover:not([disabled]) {
            background: rgba(255,255,255,.04);
          }
        }
      }

      &.bookkeeper-dashboard-action-dialog,
      &.management-action-dialog {
        min-height: unset;

        .mat-mdc-menu-content {
          padding: .5rem 0;

          .mat-mdc-menu-item {
            padding: .25rem 1rem;
            height: 100%;

            &:hover {
              background: map-get($colors, gtey-11);
            }
          }
        }
      }
    }
  }
}
