@import 'variables';

.card {
  margin-bottom: 1rem;
  padding: 1.25rem;
  box-shadow: $box-shadow;
  background-color: map-get($colors, grey-10);

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-title {
      margin-bottom: 0.25rem;

      @media (max-width: $screen-sm-max) {
        font-size: 1.75rem;
      }
    }

    &-subtitle {
      font-size: 0.875rem;
      opacity: 0.5;
      line-height: 1.25rem;

      app-card-breadcrumb:last-child {
        .card-breadcrumb-separator {
          display: none;
        }
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
  }

  &-breadcrumb {
    &-separator {
      width: 1px;
      height: 1rem;
      background-color: map-get($colors, white);
      opacity: 0.5;
      display: inline-block;
      vertical-align: text-bottom;
      margin: 0 0.4375rem;
    }
  }
}
