@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.loading {
  display: block;
  width: 100%;
  height: 0.5rem;
  background: linear-gradient(90deg, map-get($colors, grey-10) 25%, map-get($colors, grey-7) 37%, map-get($colors, grey-10) 63%);
  background-size: 400% 100%;
  animation: loading 1.4s ease infinite;
  margin-left: auto;
  margin-right: auto;
}
