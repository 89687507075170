@font-face {
  font-family: 'aspen-icon';
  src: url('fonts/aspen-icon.eot?ornq01');
  src: url('fonts/aspen-icon.eot?ornq01#iefix') format('embedded-opentype'),
    url('fonts/aspen-icon.ttf?ornq01') format('truetype'),
    url('fonts/aspen-icon.woff?ornq01') format('woff'),
    url('fonts/aspen-icon.svg?ornq01#aspen-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aspen-icon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email-fill:before {
  content: '\e952' !important;
  color: #999;
}

.icon-copy:before {
  content: '\e953' !important;
  color: #999;
}

.icon-check-list:before {
  content: '\e951' !important;
  color: #999;
}
.icon-alert-outline:before {
  content: '\e94e' !important;
  color: #474747;
}
/* .icon-note-fill:before {
  content: '\e94f' !important;
  color: #999;
}
.icon-note-outline:before {
  content: '\e950';
  color: #474747;
} */
.icon-target-outline:before {
  content: '\e94d' !important;
  color: #474747;
}

.old-icon-circle-checkmark-outline:before {
  content: '\e941' !important;
  color: #ffbf1f;
}

.icon-support-fill:before {
  content: '\e93d' !important;
  color: #b3b3b3;
}

.icon-wait:before {
  content: '\e910' !important;
  color: #ffbf1f;
}
.icon-star:before {
  content: '\e90e' !important;
  color: #999;
}
.icon-document:before {
  content: '\e909' !important;
  color: #999;
}
.icon-graph:before {
  content: '\e90b' !important;
  color: #999;
}
.icon-pie-chart:before {
  content: '\e907' !important;
  color: #757575;
}
.icon-play-icon .path1:before {
  content: '\e901' !important;
  color: rgb(255, 255, 255);
}
.icon-play-icon .path2:before {
  content: '\e903' !important;
  margin-left: -1em;
  color: rgb(233, 139, 35);
}
.icon-alert-bell:before {
  content: '\e900' !important;
  color: #999;
}
.icon-alert:before {
  content: '\e902' !important;
  color: #999;
}
.icon-alert-warning:before {
  content: '\e902' !important;
  color: #c52a1a;
}
.icon-alert-attention:before {
  content: '\e902' !important;
  color: #ffbf1f;
}
.icon-alert-attention-closed:before {
  content: '\e902' !important;
  color: #9e9e9e;
}
.icon-arrow-drop-down:before {
  content: '\e904' !important;
  color: #999;
}
.icon-arrow-drop-up:before {
  content: '\e906' !important;
  color: #999;
}
.icon-bubble-plot:before {
  content: '\e908' !important;
  color: #999;
}
.icon-check:before {
  content: '\e90a' !important;
  color: #999;
}
/* .icon-chevron-left:before {
  content: '\e90d' !important;
  color: #999;
} */
/* .icon-chevron-right:before {
  content: '\e90f' !important;
  color: #999;
} */
.icon-circle-minus:before {
  content: '\e911' !important;
  color: #999;
}
.icon-circle-plus:before {
  content: '\e913' !important;
  color: #999;
}
.icon-circle-plus-rule-page:before {
  content: '\e913' !important;
  color: #ffbf1f;
}
.icon-circle-user:before {
  content: '\e915' !important;
  color: #d04a02;
}
.icon-close:before {
  content: '\e917' !important;
  color: #999;
}
.icon-close-plus:before {
  content: '\e917' !important;
  color: #999;
  font-size: 24px;
}
.icon-confirmed:before {
  content: '\e919' !important;
  color: #999;
}
.icon-credit-card:before {
  content: '\e91b' !important;
  color: #000;
}
.icon-document1:before {
  content: '\e91d' !important;
  color: #999;
}
.icon-dollar-sign:before {
  content: '\e91f' !important;
  color: #000;
}
.icon-email:before {
  content: '\e921' !important;
  color: #999;
}
.icon-faq:before {
  content: '\e923' !important;
  color: #999;
}
.icon-h-bars .path1:before {
  content: '\e925' !important;
  color: rgb(97, 97, 97);
}
.icon-h-bars .path2:before {
  content: '\e926' !important;
  margin-left: -1em;
  color: rgb(117, 117, 117);
}
.icon-help:before {
  content: '\e927' !important;
  color: #999;
}
.icon-home:before {
  content: '\e929' !important;
  color: #999;
}
.icon-info:before {
  content: '\e92b' !important;
  color: #999;
}
.icon-kebab:before {
  content: '\e92d' !important;
  color: #999;
}
.icon-mask:before {
  content: '\e92f' !important;
}
.icon-menu-view:before {
  content: '\e930' !important;
  color: #999;
}
.icon-moderate-alert:before {
  content: '\e932' !important;
  color: #999;
}
.icon-oval:before {
  content: '\e935' !important;
  color: #757575;
}
.icon-save:before {
  content: '\e93a' !important;
  color: #999;
}
.icon-search:before {
  content: '\e93c' !important;
  color: #999;
}
.icon-settings:before {
  content: '\e93e' !important;
  color: #999;
}
.icon-share:before {
  content: '\e940' !important;
  color: #999;
}
.icon-sort-left:before {
  content: '\e942' !important;
  color: #9e9e9e;
}
.icon-sort-right:before {
  content: '\e943' !important;
  color: #9e9e9e;
}
.icon-sort:before {
  content: '\e944' !important;
  color: #9e9e9e;
}
.icon-spark-line:before {
  content: '\e945' !important;
  color: #999;
}
.icon-table-data:before {
  content: '\e947' !important;
  color: #757575;
}
.icon-v-bars .path1:before {
  content: '\e948' !important;
  color: rgb(97, 97, 97);
}
.icon-v-bars .path2:before {
  content: '\e949' !important;
  margin-left: -1em;
  color: rgb(117, 117, 117);
}
.icon-checkmark:before {
  content: '\e905' !important;
  color: #fff;
}
.icon-checkmark-success:before {
  content: '\e905' !important;
  color: #22992e;
}
.icon-list:before {
  content: '\e90c' !important;
  color: #999;
}
.icon-edit:before {
  content: '\e914' !important;
  color: #ffbf1f;
}
.icon-delete:before {
  content: '\e912' !important;
  color: #ffbf1f;
}

.icon-delete-primary:before {
  content: '\e912' !important;
  color: #eb8c00;
}

.icon-icon-outline-filter:before {
  content: '\e916' !important;
  color: #fff;
}

.icon-icon-fill-filter:before {
  content: '\e918' !important;
  color: #fff;
}

.icon-icon-fill-download:before {
  content: '\e91a' !important;
  color: #eb8c00;
}

.icon-icon-outline-chevron-bottom:before {
  content: '\e91c' !important;
  color: #eb8c00;
}

.icon-chevron-right-tree:before {
  content: '\e90f' !important;
  color: #eb8c00;
}

.icon-default-avatar:before {
  content: '\e91e' !important;
  color: #fff;
}

.icon-icon-fill-circle-plus:before {
  content: '\e920' !important;
  color: #ffbf1f;
}

.icon-icon-fill-circle-check:before {
  content: '\e922' !important;
  color: #22992e;
}
.icon-fill-calendar:before {
  content: '\e924' !important;
  color: #9e9e9e;
}
.icon-fill-calendar-active:before {
  content: '\e924' !important;
  color: #eb8c00;
}
.icon-clipboard:before {
  content: '\e928' !important;
  color: #999;
}
.icon-outline-comment:before {
  content: '\e92a' !important;
  color: #999;
  font-size: 4rem;
}
.icon-fill-comment:before {
  content: '\e92c' !important;
  color: #999;
}

.icon-outline-attachment:before {
  content: '\e92e' !important;
  color: #eb8c00;
  font-size: 1.25rem;
}

.icon-fill-archive:before {
  content: '\e931' !important;
  /* color: #d8d8d8; */
}

.icon-solid-capital:before {
  content: '\e933' !important;
  color: #fff;
}

.icon-fill-chevron-down:before {
  content: '\e934' !important;
  color: #fff;
}

.icon-rich-text-editor-hyperlink:before {
  content: '\e936' !important;
  color: #f5f5f5;
}

.icon-circle-delete:before {
  content: '\e938' !important;
}

.icon-camera:before {
  content: '\e939' !important;
}

.icon-upload:before {
  content: '\e93b' !important;
}

/* .icon-avatar-outline:before {
  content: '\e946' !important;
  color: #474747;
} */
.icon-audience-fill-notappkit:before {
  content: '\e94a' !important;
  color: #474747;
}

.icon-fill-file:before {
  content: '\e94b' !important;
}

.icon-view-off-outline::before {
  color: #757575;
}

.icon-circle-warning-outline::before {
  color: #eb8c00;
  font-size: 24px;
}

.icon-fill-circle-check-black:before {
  content: '\e922' !important;
  color: #252525;
}

.icon-fill-circle-check-white:before {
  content: '\e922' !important;
  color: #fff;
}
/*
.icon-chevron-double-left-outline:before {
  content: '\e954' !important;
}
.icon-chevron-double-right-outline:before {
  content: '\e955' !important;
} */
