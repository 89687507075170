$primary-color: map-get($colors, primary);
.navbar-menu {
  color: white !important;
  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .mdc-list-item__primary-text {
      display: flex;
    }
  }

  .mat-mdc-menu-item {
    height: 2rem;
    font-size: 0.875rem;
    line-height: 1.5;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    transition: background 0.3s ease;
    min-height: unset;
    width: 237px;
    &:hover {
      background: map-get($colors, grey-10);
    }
  }

  .mat-divider {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top-color: map-get($colors, grey-11);
  }

  .navbar-profile {
    &.mat-mdc-menu-item {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      height: 3.25rem;
      cursor: default;
      display: flex;
      align-items: center;

      &:hover {
        background: transparent;
      }
    }

    &-avatar {
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      background-color: map-get($colors, black);
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-text {
      display: flex;
      justify-content: space-between;
      flex: 1;
      flex-grow: 1;
      flex-shrink: 1;
      flex-direction: column;
    }

    &-title {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      margin-bottom: 0.25rem;
    }

    &-subtitle {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}
body.panel-mobile-body-bk {
  @media (max-width: $screen-sm-max) {
    .main-layout {
      opacity: 0.7;
    }
  }
}

.main-layout {
  background-color: map-get($colors, grey-12);
  .mat-drawer-container {
    background-color: transparent;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: $cdk-overlay-dark-backdrop;
  }

  .mat-sidenav {
    border-right: 0;
    height: 100vh;
    background: map-get($colors, grey-10);
    transition: padding-top 0.12s ease-out;
    @media (min-width: $screen-lg-min) {
      width: $sidenav-width;
      padding-top: $header-height;
    }
    @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
      width: $sidenav-width-md;
    }
    @media (max-width: $screen-sm-max) {
      width: $sidenav-width-sm;
    }
    .sidenav {
      display: flex;
      flex-direction: column;
      height: 100%;
      &-content {
        max-height: calc(100% - 72px);
        overflow-y: auto;
        background-color: map-get($colors, grey-12);
      }
      &-back {
        padding: 1.25rem;
        display: flex;
        align-items: center;
        .arrow-left {
          font-size: 1.5rem;
          cursor: pointer;
        }
        @media (min-width: $screen-lg-min) {
          display: none;
        }
        @media (max-width: $screen-sm-max) {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }
      }

      &-firm-logo {
        // margin-left: 1.5rem;
        img {
          width: auto;
          height: 2rem;
          @media (max-width: $screen-sm-max) {
            height: 1.75rem;
          }
        }
      }

      &-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        background-color: map-get($colors, grey-10);
        border-left: 0.25rem solid map-get($colors, grey-10);
        padding-right: 0.25rem;
        transition: background-color 0.3s ease, border-left-color 0.3s ease;

        &-content {
          text-align: center;
          overflow: hidden;
          font-size: 1.125rem;
          &,
          em:before {
            color: map-get($colors, white);
          }
          @media (min-width: $screen-lg-min) {
            em {
              font-size: 1.75rem;
            }

            span {
              display: block;
              margin-top: 0.5rem;
              font-size: 0.875rem;
              line-height: 1.25rem;
            }
          }
        }

        @media (max-width: $screen-md-max) {
          padding: 1rem 1.5rem;

          &:hover {
            background-color: map-get($colors, grey-11);
            border-left-color: map-get($colors, grey-11);
          }

          &.active {
            background-color: map-get($colors, grey-12);
            border-left-color: $primary-color;
          }

          em {
            font-size: 2rem;
            margin-right: 1.5rem;
          }

          em,
          span {
            line-height: 2rem;
            vertical-align: middle;
          }
        }

        @media (min-width: $screen-lg-min) {
          height: 7rem;
          justify-content: center;
          margin-top: 0.125rem;
          margin-bottom: 0.125rem;

          & + .sidenav-item {
            margin-top: 0;
          }

          &:hover {
            background-color: map-get($colors, grey-9);
            border-left-color: map-get($colors, grey-9);
          }

          &.active {
            background-color: map-get($colors, grey-12);
            border-left-color: map-get($colors, grey-9);
          }
        }
      }

      @media (min-width: $screen-lg-min) {
        // background-color: map-get($colors, grey-12);
      }
    }
  }

  .mat-drawer-content {
    .sidenav-content {
      padding-left: $gutter;
      padding-right: $gutter;

      @media (min-width: $screen-lg-min) {
        height: calc(100vh - #{$footer-height});
        overflow: auto;
        padding-top: $header-height + $content-padding-top;
        padding-bottom: $content-padding-bottom;
      }

      @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
        height: calc(100vh - #{$footer-height-md});
        overflow: auto;
        padding-top: $header-height-md + $content-padding-top-md;
        padding-bottom: $content-padding-bottom-md;
      }

      @media (max-width: $screen-sm-max) {
        height: calc(100vh - #{$footer-height-sm});
        overflow: auto;
        padding-top: $header-height-sm + $content-padding-top-sm;
        padding-bottom: $content-padding-top-sm;
      }

      &.sidenav-content-fit {
        @media (min-width: $screen-lg-min) {
          height: calc(100vh - #{$footer-height});
        }

        @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
          height: calc(100vh - #{$footer-height-md});
        }

        @media (max-width: $screen-sm-max) {
          height: calc(100vh - #{$footer-height-sm});
        }

        > .container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .mat-mdc-list-base {
    padding-top: 0;
    padding-bottom: 0;
  }

  .top-guide-banner {
    display: none;
  }

  .navbar {
    display: flex;
    position: fixed;
    min-width: $header-fixed-width;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding-left: 1.25rem;
    padding-right: $gutter;
    background-color: map-get($colors, grey-11);
    transition: top 0.12s ease-out, margin-top 0.12s ease-out,
      height 0.12s ease-out, box-shadow 0.12s ease-out;
    font-weight: 400;
    border-bottom: 0.25rem solid $primary-color;

    @media (min-width: $screen-lg-min) {
      height: $header-height;
      border-bottom-width: 0.25rem;
    }

    @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
      height: $header-height;
      border-bottom-width: 0.25rem;
    }

    @media (max-width: $screen-sm-max) {
      height: $header-height-sm;
      border-bottom-width: 0.125rem;
    }

    &-kpi-date-select-container,
    &-vertical-line,
    &-page-title {
      opacity: 0;
      transition: opacity 0.12s ease-out;
    }

    @media (max-width: $screen-sm-max) {
      &-links,
      &-vertical-line {
        display: none;
      }
    }

    &-kpi-date-select-container {
      @media (min-width: $screen-md-min) {
        position: relative;
        height: 2.25rem;
      }
      margin-right: 2rem;
      app-kpi-date-select {
        @media (min-width: $screen-md-min) {
          position: absolute;
          right: 0;
        }
        .kpi-date-select {
          .desc {
            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
              display: none;
            }
          }
        }
      }
      @media (max-width: $screen-sm-max) {
        position: absolute;
        bottom: 1rem;
      }
    }

    &-vertical-line {
      width: 1px;
      height: 2rem;
      background-color: map-get($colors, grey-7);
    }

    &-topbar {
      display: flex;
      align-items: center;
      &-line {
        width: 1px;
        height: 2rem;
        background-color: map-get($colors, grey-7);
        &.navbar-topbar-line-transform {
          position: relative;
          left: 1px;
        }
      }
      @media (max-width: $screen-sm-max) {
        position: absolute;
        right: 1.5rem;
        &-line-transform {
          display: none;
        }
      }
      &-info {
        margin-right: 1.125rem;
      }
      &-settings {
        margin-left: 1.125rem;
      }
      &-user {
        background-color: map-get($colors, grey-8);
        color: map-get($colors, white);
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        box-shadow: none;
        margin-left: 2rem;
        .mdc-button__label {
          font-weight: 700;
          padding-top: 0;
          padding-bottom: 0;
          letter-spacing: 0.1px;
          font-size: 14px;
        }
        &-wrap {
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          line-height: 1.25;
          margin-left: 1rem;
          box-shadow: none;
        }
        &-tenant {
          max-width: 350px;
          color: map-get($colors, grey-6);
        }
        &-name {
          font-weight: 500;
        }
      }
    }

    .mat-icon-button {
      width: 3.25rem;
      height: 3.25rem;
      color: transparent;
      em {
        font-size: 1.5rem;
        &::before {
          color: map-get($colors, grey-7);
        }
      }
    }

    &-ham-btn {
      margin-right: 0.375rem;
      margin-left: -0.875rem;
      z-index: 100;
      @media (max-width: $screen-sm-max) {
        margin-right: 0.125rem;
      }
    }

    &-page-title {
      position: absolute;
      top: 2.9375rem;
      font-size: 1.375rem;
      font-weight: 300;
      line-height: 1.27;
    }

    &-firm-logo {
      height: 2rem;
      width: auto;
      transition: height 0.12s ease-out, width 0.12s ease-out,
        margin-top 0.12s ease-out;
      img {
        height: 100%;
        width: auto;
      }

      @media (max-width: $screen-sm-max) {
        height: 1.5rem;
      }
    }

    &-links {
      position: relative;
      font-size: 1.5rem;
      text-decoration: none;
      letter-spacing: -0.22px;
      margin-left: 0.5rem;
      transition: font-size 0.12s ease-out, letter-spacing 0.12s ease-out,
        margin-top 0.12s ease-out;
      a {
        color: $primary-color;
      }
      @media (max-width: $screen-sm-max) {
        display: none;
      }
      .right-corner-font {
        position: absolute;
        top: 3px;
        height: 0.5rem;
        line-height: 1.33;
        color: $primary-color;
        font-size: 6px;
      }
    }

    .mat-icon-button.cdk-program-focused .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }

  .footer {
    height: $footer-height;
    line-height: $footer-height;
    font-size: 12px;
    font-weight: 400;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: map-get($colors, grey-12);
    transition: height 0.12s ease-out, bottom 0.12s ease-out;

    &-info {
      white-space: normal;
    }

    &-links {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      > li + li {
        margin-left: 1.5rem;
      }
      > li a {
        color: map-get($colors, white);
        &:hover {
          color: map-get($colors, grey-7);
        }
      }
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
      height: $footer-height-md;
      line-height: 0.875rem;
      .flex-spacer {
        margin-top: 1rem;
        flex-grow: 0;
      }
      &.mat-toolbar-single-row {
        flex-direction: column;
        align-items: center;
      }
      &-links {
        margin-top: 1rem;
      }
    }

    @media (max-width: $screen-sm-max) {
      height: $footer-height-sm;
      line-height: 1rem;
      text-align: center;
      flex-direction: column;
      padding: 1rem 1.5rem;

      &-info {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-links {
        margin-top: 1rem;
        width: 100%;
        justify-content: center;
        > li {
          padding: 0;
        }
      }
    }
  }

  &.main-layout-is-mobile.main-layout-sidenav-opened {
    .mat-drawer-container {
      z-index: 3;
      height: 100vh;
    }
  }

  &.main-layout-show-sidenav {
    .navbar-page-title {
      left: 4.25rem;
    }
  }

  &.main-layout-show-top-guide-banner {
    .top-guide-banner {
      top: 0;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: map-get($colors, orange-2);
      z-index: 3;
      transition: height 0.12s ease-in, top 0.12s ease-out;

      &-hide {
        display: none;
      }

      @media (min-width: $screen-lg-min) {
        height: $top-guide-banner-height;
      }

      @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
        height: $top-guide-banner-height-md;
      }

      @media (max-width: $screen-sm-max) {
        height: $top-guide-banner-height-sm;
      }

      app-back-deprecated {
        position: absolute;
        .back {
          margin-left: $gutter;
          margin-bottom: 0;

          &:hover {
            .icon-left-chevron-outline::before,
            .back-text {
              color: map-get($colors, grey-11) !important;
            }
          }

          .icon-left-chevron-outline {
            &:before {
              color: map-get($colors, grey-11) !important;
            }
          }

          &-text {
            color: map-get($colors, grey-11);
          }
        }
      }

      &-title {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height: 1.5;
        height: 3.25rem;
        color: map-get($colors, grey-11);
        &-name {
          font-size: 1.375rem;
          line-height: 1.27;
          font-weight: 500;
        }
      }
    }
    .navbar {
      top: $top-guide-banner-height;
      @media (max-width: $screen-sm-max) {
        top: $top-guide-banner-height-sm;
      }
    }
    @media (min-width: $screen-lg-min) {
      .mat-sidenav:not(.panel-container) {
        padding-top: $top-guide-banner-height + $header-height;
      }
    }
    .mat-drawer-content .sidenav-content {
      padding-top: $header-height + 7rem;

      @media (max-width: $screen-sm-max) {
        padding-top: $header-height-sm + 5.375rem;
      }
    }
  }

  &.main-layout-twoline-header {
    .navbar {
      box-shadow: $box-shadow;

      @media (min-width: $screen-lg-min) {
        height: $header-height-twoline;
      }
      @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
        height: $header-height-twoline-md;
      }
      @media (max-width: $screen-sm-max) {
        height: $header-height-twoline-sm;
        align-items: flex-start;
        padding-top: 0.5rem;
      }

      &-kpi-date-select-container,
      &-vertical-line,
      &-page-title {
        opacity: 1;
      }

      &-links {
        font-size: 1rem;
        letter-spacing: -0.15px;
        margin-top: -2rem;
        .right-corner-font {
          top: 6px;
          height: 5px;
          line-height: 1.25;
          font-size: 4px;
        }
      }

      &-firm-logo {
        @media (min-width: $screen-md-min) {
          height: 1.33rem;
          margin-top: -2rem;
        }
        @media (max-width: $screen-sm-max) {
          margin-top: 11.8px;
        }
      }
    }

    @media (min-width: $screen-lg-min) {
      .mat-sidenav {
        padding-top: $header-height-twoline;
      }
    }
  }

  &.main-layout-show-top-guide-banner.main-layout-twoline-header {
    @media (min-width: $screen-lg-min) {
      .mat-sidenav {
        padding-top: $top-guide-banner-height + $header-height-twoline;
      }
    }
  }
  .menu-container.mat-drawer:not(.mat-drawer-opened, .hide-sidenav) {
    transform: none;
    visibility: visible !important;
    display: block !important;
    width: 45px;
    z-index: 1;
  }
  .side-cloed {
    margin-left: 45px;
  }
}

// Below are for gen 2

@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

body {
  --spacing-40-px: rem(40px);
  --max-content-width: 72.5rem;
}

.layout-container.has-data-table {
  --max-content-width: 100%;
}

.layout-normal-container {
  display: grid;
  grid-template-columns: var(--sidebar-width-open, var(--sidebar-width-closed)) 4fr;
  grid-template-areas: 'sidenav content';
  transition: all 0.2s ease;
}

.layout-category-container {
  display: grid;
  --margin-width: calc(
    100vw - var(--max-content-width) -
      var(--sidebar-width-open, var(--sidebar-width-closed))
  );
  grid-template-columns:
    var(--sidebar-width-open, var(--sidebar-width-closed)) calc(
      calc(var(--margin-width) * 0.5) + calc(var(--max-content-width) * 0.75)
    )
    calc(
      calc(var(--margin-width) * 0.5) + calc(var(--max-content-width) * 0.25)
    );
  // grid-template-rows: var(--header-height) 1fr;
  grid-template-areas: 'sidenav content category';
  transition: all 0.2s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.layout-category-container .category {
  grid-area: category;
  position: sticky;
  top: var(--header-height);
  padding-left: $spacing-5;
  // z-index: -1;
}

.content-wrapper {
  grid-area: content;
  background-color: $color-background-default;
  max-width: calc(
    100vw - var(--sidebar-width-open, var(--sidebar-width-closed))
  );
}

.content-wrapper-category {
  padding-right: $spacing-5;
}
.layout-normal-container .content-window,
.non-login-content-window {
  margin: auto;
  max-width: min(var(--max-content-width), 100%);
}

.category-container-section {
  position: fixed;
}

.layout-wrapper-noSideNav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height) auto;
  grid-template-areas:
    'header'
    'content';
}

.header {
  // grid-area: header;
  background-color: $color-background-default;
  position: fixed;
  // position: -webkit-sticky;
  width: 100%;
  height: var(--header-height);
  top: 0;
  z-index: 700;
}

.sidenav-container {
  display: contents;
  z-index: 98;
  background-color: $color-background-default;
  position: sticky;
  position: -webkit-sticky;
  max-height: calc(100vh - var(--header-height)) !important;
  .ap-navigation-wrapper {
    height: calc(100vh - var(--header-height)) !important;
  }
  .navigation-wrapper {
    grid-area: sidenav;
    position: fixed;
    // position: sticky;
    // position: -webkit-sticky;
    top: var(--header-height);
    height: calc(100vh - var(--header-height)) !important;
  }
}

#maincontent {
  flex: 1 0 0;
}

.content-has-header {
  max-height: calc(100vh - $gen2-header-height);
}

.footer {
  padding: $spacing-7 0 $spacing-4;
  width: 100%;
  // position: relative;
  z-index: 1;
}

.navbar-topbar-user-wrap {
  color: $color-text-heading;
  display: flex;
  flex-direction: column;
  font-size: $typography-body;
  line-height: $typography-line-height-2;
  margin-left: $spacing-4;
  box-shadow: none;
  .navbar-topbar-user-tenant {
    color: $color-text-light;
  }
  .navbar-topbar-user-name {
    color: $color-background-tertiary;
  }
}

.ap-navigation-item.selected,
.ap-navigation-item.active {
  // custom styles for the selected item
  font-weight: $font-weight-2;
  color: $color-text-primary !important;
  background-color: $color-background-hover-selected !important;
}

// .layout-container,
.layout-wrapper-pure,
.content-window {
  height: 100%;
}
.layout-container {
  min-height: calc(100% - var(--header-height));
  // margin-top: var(--header-height);
  top: var(--header-height);
  position: relative;
}
.content-wrapper {
  // min-height: 100%;
  min-height: calc(100vh - var(--header-height)); // TODO delete
  overflow: hidden;
}

.ap-option-item.avatar-dropdown-user-info {
  justify-content: unset;
  &:hover:not(.disabled) {
    background-color: unset;
  }
  cursor: unset;
}
