@mixin icon-confirmed {

  .icon-confirmed,
  .icon-alert {
    height: 32px;
    width: 32px;
    font-size: 2rem;
    line-height: 2rem;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  @media (max-width: $screen-sm-max) {
    flex-direction: column;
    margin-bottom: 2rem;

    .icon-confirmed,
    .icon-alert {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .icon-confirmed:before {
    color: map-get($colors, green);
  }

  .icon-alert:before {
    color: map-get($colors, red);
  }
}

.page {
  width: 100%;
  max-width: 38rem;
  margin: 2.75rem auto 0;

  a {
    color: map-get($colors, primary);
    text-decoration: none;
    word-break: break-all;

    &:hover {
      color: map-get($colors, primary-hover);
    }
  }

  @media (max-width: $screen-sm-max) {
    margin-top: 3.5rem;
  }

  &-header {
    text-align: center;
    font-weight: 300;
  }

  &-content {
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;

    p {
      margin-bottom: 3.5rem;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &-button {
    width: 100%;
    max-width: 25rem;

    @media (max-width: $screen-sm-max) {
      max-width: 20rem;
    }
  }

  &.welcome {
    .page-header {
      margin-bottom: 5rem;

      @media (max-width: $screen-sm-max) {
        margin-top: 3.5rem;
      }
    }
  }

  &.empty {
    margin-top: 1.75rem;

    @media (max-width: $screen-sm-max) {
      margin-top: 2.5rem;
    }

    .page-header {
      font-weight: 400;
      margin-bottom: 2.5rem;
    }

    .page-content {
      img {
        max-width: 25rem;
        width: 100%;
        height: auto;

        @media (max-width: $screen-sm-max) {
          max-width: 20rem;
        }
      }
    }
  }

  &.sign {
    .page-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-bottom: 2.25rem;
      @include icon-confirmed();

      h2 {
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .page-content {
      @media (max-width: $screen-sm-max) {
        font-size: 1rem;

        p {
          margin-bottom: 4rem;
        }
      }
    }
  }

  &.timeout {
    .page-header {
      margin-bottom: 2.25rem;
    }

    .page-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p,
      img {
        margin-bottom: 3.5rem;

        @media (max-width: $screen-sm-max) {
          margin-bottom: 2.5rem;
        }
      }

      img {
        width: 18.5rem;
        height: auto;
      }
    }
  }

  &.disconnected {
    .page-header {
      font-weight: 400;
      margin-bottom: 2.5rem;
    }
  }

  &.form-submission {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;

    .page-content {
      margin-bottom: 0px;
      @include icon-confirmed();

      .text {
        height: 40px;
        font-size: 2.25rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
      }

      .mb-top {
        margin-top: 2.25rem;
      }
    }

    button {
      min-width: 11.25rem;
      width: 25rem;
      max-width: 25rem;
      margin-bottom: 1.375rem;
    }
  }
}
