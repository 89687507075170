@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      @media (max-width: $min-vw) {
        // font-size: $min-font-size;
      }
      @media (min-width: $min-vw + 1px) and (max-width: $max-vw - 1px) {
        // font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media (min-width: $max-vw) {
        // font-size: $max-font-size;
      }
    }
  }
}

@mixin input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin text-ellipsis() {
  @content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-wrap-ellipsis-active {
  @include text-ellipsis();
  color: map-get($colors, primary);
}

@mixin database-scrollable($margin-between-viewport: 24rem) {
  @content;
  max-height: calc(100vh - $margin-between-viewport) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

@mixin view-detail() {
  @content;
  height: 1.5rem;
  font-size: 1.125rem;
  letter-spacing: 0;
  color: map-get($colors, primary);
  line-height: 1.33;
  text-decoration: none;
  border-right: 1px solid #757575;
  cursor: pointer;
  &:hover {
    color: map-get($colors, primary-hover);
  }
}

@mixin icon-sort() {
  .sort-icons {
    em {
      position: absolute;
      left: 3px;
    }
    display: inline;
    position: relative;
    em.icon-arrow-drop-down {
      top: 7px;
    }
    em.icon-arrow-drop-up {
      top: -3px;
    }
  }
}

@mixin icon-sort-active() {
  em.sort-icon-active {
    &::before {
      color: map-get($colors, primary);
    }
  }
}

@mixin icon-primary-hover() {
  cursor: pointer;
  &::before {
    color: map-get($colors, primary);
  }
  &:hover {
    &::before {
      color: map-get($colors, primary-hover);
    }
  }
}

@mixin user-avatar() {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #424242;
  transform: translateY(-50%);
  text-align: center;
  line-height: 2rem;
  color: map-get($colors, white);
}

@mixin full-name() {
  height: 1rem;
  line-height: 1rem;
  margin-bottom: 0.125rem;
  overflow: hidden;
}
